import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import "../styles/HowReliefExchangeOperates.scss";
import introduceIcon from "../assets/images/introduceIcon.png";
import assignIcon from "../assets/images/assignIcon.png";
import purchaseIcon from "../assets/images/purchaseIcon.png";
const HowReliefExchangeOperates = () => {
    return (
        <Container id="how-relief-exchange-operates" className="py-2 py-sm-2 py-md-5 py-lg-5">
            <h1 className="how-relief-exchange-operates-title">
                How “<span>Relief Exchange</span>” Operates
            </h1>
            <Row className="g-4 py-5">
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={introduceIcon} alt="introduce-icon" className="img-fluid" />
                        <Card.Body>
                        <Card.Title className="text-center">Introduce</Card.Title>
                        <Card.Text>
                            It's your network that may be a factor changes your way of being ambitious. Connect to the experienced CTOs, explore the experiences and journey.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={assignIcon} alt="introduce-icon" className="img-fluid" />
                        <Card.Body>
                        <Card.Title className="text-center">Assign</Card.Title>
                        <Card.Text>
                            Innovation finds the keys to futuristic solution. CTO forum let you access to the innovation center; where Ideas grow, greatness create.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={purchaseIcon} alt="introduce-icon" className="img-fluid" />
                        <Card.Body>
                        <Card.Title className="text-center">Purchase</Card.Title>
                        <Card.Text>
                            The way of innovating, resources you need can be found here with the data and insights-what makes your journey easy and understandable?
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default HowReliefExchangeOperates;