import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import "../styles/MeetOurMember.scss";
import meetMemberImage1 from "../assets/images/meetMemberImage1.png";
import meetMemberImage2 from "../assets/images/meetMemberImage2.png";
import meetMemberImage3 from "../assets/images/meetMemberImage3.png";
import meetMemberImage4 from "../assets/images/meetMemberImage4.png";
const MeetOurMember = () => {
    return (
        <Container id="meet-our-member">
            <h1 className="title text-center">
                Meet Our Members
            </h1>
            <p className="description meet-our-member-description">
            Magna nunc gravida senectus vulputate ligula fames ultricies quam vitae. Lectus tortor, facilisi pretium ac mauris augue turpis. Sed erat sapien vitae.
            </p>
            <Row xs={1} md={4} className="g-4">
                <Col>
                    <Card>
                        <Card.Img variant="top" src={meetMemberImage1} className="img-fluid" alt="" />
                        <Card.Body>
                        <Card.Title className="meet-our-member-title">Rashed Mahmud</Card.Title>
                        <Card.Text className="description text-center">
                            Executive Chairman
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <div className="social-icons text-center">
                        <span className="social-icon">
                        <FaFacebookF />
                        </span>
                        <span className="social-icon">
                        <FaTwitter />
                        </span>
                        <span className="social-icon">
                        <FaLinkedinIn/>
                        </span>
                    </div>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={meetMemberImage2} className="img-fluid" alt="" />
                        <Card.Body>
                        <Card.Title className="meet-our-member-title">Michael Wong</Card.Title>
                        <Card.Text className="description text-center">
                            Director
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <div className="social-icons text-center">
                        <span className="social-icon">
                        <FaFacebookF />
                        </span>
                        <span className="social-icon">
                        <FaTwitter />
                        </span>
                        <span className="social-icon">
                        <FaLinkedinIn/>
                        </span>
                    </div>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={meetMemberImage3} className="img-fluid" alt="" />
                        <Card.Body>
                        <Card.Title className="meet-our-member-title">Bahjat Aman</Card.Title>
                        <Card.Text className="description text-center">
                            Managing DIrector
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <div className="social-icons text-center">
                        <span className="social-icon">
                        <FaFacebookF />
                        </span>
                        <span className="social-icon">
                        <FaTwitter />
                        </span>
                        <span className="social-icon">
                        <FaLinkedinIn/>
                        </span>
                    </div>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src={meetMemberImage4} className="img-fluid" alt="" />
                        <Card.Body>
                        <Card.Title className="meet-our-member-title">Uttam Singh</Card.Title>
                        <Card.Text className="description text-center">
                            Director
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <div className="social-icons text-center">
                        <span className="social-icon">
                        <FaFacebookF />
                        </span>
                        <span className="social-icon">
                        <FaTwitter />
                        </span>
                        <span className="social-icon">
                        <FaLinkedinIn/>
                        </span>
                    </div>
                </Col>
            </Row>
            <Button className="browse-all my-5">Browse All</Button>
        </Container>
    );
};

export default MeetOurMember;