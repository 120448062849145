import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "../styles/FeaturedInNewsPaper.scss";
import featuredInNewsPaper from "../assets/images/featuredInNewsPaper.png";
import featuredImage1 from "../assets/images/featuredImage1.png";
import featuredImage2 from "../assets/images/featuredImage2.png";
import featuredAddIcon from "../assets/images/featuredAddMoreIcon.png";
import featuredArrowIcon from "../assets/images/featuredArrowIcon.png";
import ButtonComposition from './ButtonComposition/ButtonComposition';
import { BiBookAlt } from 'react-icons/bi';
const FeaturedInNewsPaper = () => {
    return (
        <Container id="featured-in-newspaper">
            <Row>
                <Col md={8}>
                    <h1 className="title">
                        Relief Exchange featured in News Paper
                    </h1>
                    <p className="description py-4">
                        A faucibus purus justo, faucibus et duis nec sodales. Diam porta odio amet ultricies etiam. Arcu id arcu tellus viverra viverra diam vitae arcu cursus at. 
                    </p>
                    <h2 className="secondary-title">
                        Featured Writers
                    </h2>
                    <div className="d-flex py-5 flex-wrap">
                        <div className="featured-person d-flex align-items-center">
                            <Image src={featuredImage1} alt="featured-person" className="img-fluid" />
                            <h5 className="featured-person-title">
                                Rafeed Khan
                            </h5>
                        </div>
                        <div className="featured-person d-flex align-items-center">
                            <Image src={featuredImage2} alt="featured-person" className="img-fluid" />
                            <h5 className="featured-person-title">
                                Tanvir Hasan Sadi
                            </h5>
                        </div>
                        <div className="featured-person d-flex align-items-center">
                            <Image src={featuredAddIcon} alt="featured-person" className="img-fluid" />
                            <h5 className="featured-person-title">
                                And More
                            </h5>
                        </div>
                        <div className="featured-person d-flex align-items-center">
                            <Image src={featuredArrowIcon} alt="featured-person" className="img-fluid" />
                        </div>
                    </div>
                    <div className="featured-article featured-article-description">
                        <h2 className="secondary-title">
                            Fractured Articles
                        </h2>
                        <Row className="py-5">
                            <Col md={6}>
                                <p><span><BiBookAlt/></span>Elementum scelerisque quam accumsan et, fusce aenean. Feugiat eu pulvinar ac ut ac sed velit. Id.</p>
                                <p><span><BiBookAlt/></span>Elementum scelerisque quam accumsan et, fusce aenean. Feugiat eu pulvinar ac ut ac sed velit. Id.</p>
                                <p><span><BiBookAlt/></span>Elementum scelerisque quam accumsan et, fusce aenean. Feugiat eu pulvinar ac ut ac sed velit. Id.</p>
                            </Col>
                            <Col md={6}>
                                <p><span><BiBookAlt/></span>Elementum scelerisque quam accumsan et, fusce aenean. Feugiat eu pulvinar ac ut ac sed velit. Id.</p>
                                <p><span><BiBookAlt/></span>Elementum scelerisque quam accumsan et, fusce aenean. Feugiat eu pulvinar ac ut ac sed velit. Id.</p>
                                <p><span><BiBookAlt/></span>Elementum scelerisque quam accumsan et, fusce aenean. Feugiat eu pulvinar ac ut ac sed velit. Id.</p>
                            </Col>
                        </Row>
                    </div>
                    <ButtonComposition>{"Read Now"}</ButtonComposition>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                    <Image src={featuredInNewsPaper} alt="featured-image" className="img-fluid"/>
                </Col>
            </Row>

        </Container>
    );
};

export default FeaturedInNewsPaper;