import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "../styles/WantToStartInvesting.scss";
import speakerIcon from "../assets/images/speaker.png";
import reliefExchangePersonImage from "../assets/images/reliefExchangePersonImage.png";
import wayToStartInvesting from "../assets/images/wayToStartInvesting.png";
// import ButtonComposition from './ButtonComposition/ButtonComposition';

const WantToStartInvesting = () => {
    return (
        <Container className="py-2 py-sm-2 py-md-5 py-lg-5">
            <Row>
                <Col md={6} className="d-flex align-items-center">
                    <Image src={wayToStartInvesting} alt="" className="img-fluid"/>
                    {/* <ButtonComposition>{"Get in touch"}</ButtonComposition>
                    <p className="way-to-start-investing-description">
                        Got any question about investment? Get asnswerd by <span>Relief Exchange</span>  Fin-Tech leaders of Bangladesh
                    </p> */}
                </Col>
                <Col md={6}>
                    <div className="want-to-start-investing-relief-exchange-section">
                        <h1 className="want-to-start-investing-relief-exchange-title">Relief Exchange No.1 Brokerage House of  Bangladesh</h1>
                        <p className="want-to-start-investing-relief-exchange-description py-5">
                            Donec id tempor hendrerit iaculis tempus. Aliquet ut lacus, amet, mauris arcu. Justo sed non, integer fames purus sit ut ac. Congue at suspendisse neque, quam vivamus ullamcorper tempor et. Donec aliquam odio mi enim faucibus consequat. Vitae lectus.Donec id tempor hendrerit iaculis tempus. Aliquet ut lacus, amet, mauris arcu. Justo sed non, integer fames purus sit ut ac. Congue at suspendisse neque, quam vivamus.
                        </p>
                    </div>
                    <Row>
                        <Col md={2} className="d-flex align-items-center justify-content-center flex-column">
                            <Image src={speakerIcon} alt="speaker-icon"/>
                            <h6 className="speaker-title">Speaker</h6>
                        </Col>
                        <Col md={10} className="d-flex align-items-center">
                            <Image src={reliefExchangePersonImage} alt="person-image"/>
                            <div className="relief-exchange-person">
                                <h5 className="relief-exchange-person-name">
                                    Rashed Mahmud
                                </h5>
                                <h6 className="relief-exchange-person-designation">
                                    Executive Chairman
                                </h6>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default WantToStartInvesting;