import React from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import Marquee from "react-fast-marquee";
import "../styles/Collaboration.scss";
import clientImage1 from "../assets/images/clients-1.png"
import clientImage2 from "../assets/images/clients-2.png"
import clientImage3 from "../assets/images/clients-3.png"
import clientImage4 from "../assets/images/clients-4.png"
import clientImage5 from "../assets/images/clients-5.png"
const Collaboration = () => {
    return (
        <Container id="collaboration" className="py-2 py-sm-2 py-md-5 py-lg-5">
            <h1 className="title text-center">
                Collaborations and Partnerships
            </h1>
            <p className="description collaboration-description">
                Risus non at tempus, eget dolor lorem viverra justo. Nunc tellus bibendum ridiculus tortor vel nunc mattis.
            </p>
            <Marquee gradient={false} className="py-5">
                <Image src={clientImage1} alt="" />
                <Image src={clientImage2} alt=""/>
                <Image src={clientImage3} alt=""/>
                <Image src={clientImage4} alt=""/>
                <Image src={clientImage5} alt=""/>
            </Marquee>
            
            <Button className='patners-corner my-5'>Patners Corner</Button>
        </Container>
    );
};

export default Collaboration;