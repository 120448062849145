import './App.scss';
import BestWayToInvest from './components/BestWayToInvest';
import Collaboration from './components/Collaboration';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import FeaturedInNewsPaper from './components/FeaturedInNewsPaper';
import FinTechBasedDigitalHouse from './components/FinTechBasedDigitalHouse';
import HowReliefExchangeOperates from './components/HowReliefExchangeOperates';
import InvesmentOffering from './components/InvesmentOffering';
import MeetOurMember from './components/MeetOurMember';
import WantToStartInvesting from './components/WantToStartInvesting';
import WhatPeopleSay from './components/WhatPeopleSay';

function App() {
  return (
    <div className="App">
      <Header />
      <BestWayToInvest />
      <WantToStartInvesting />
      <FinTechBasedDigitalHouse />
      <InvesmentOffering />
      <HowReliefExchangeOperates />
      <FeaturedInNewsPaper />
      <WhatPeopleSay />
      <MeetOurMember />
      <Collaboration />
      <Footer />
    </div>
  );
}

export default App;
