import React from 'react';
import "../../styles/ButtonComposition.scss"
const ButtonComposition = ({children}) => {
    return (
        <div className=' pb-5 pb-sm-5 pb-md-0 pb-lg-0'>
            <span className="similar-button-composition">{children}</span>
        </div>
    );
};

export default ButtonComposition;