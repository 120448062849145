import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "../styles/FinTechBasedDigitalHouse.scss"
import ButtonComposition from './ButtonComposition/ButtonComposition';
import fintechBasedDigitalHouseImage from "../assets/images/fintechBasedDigitalHouseImage.png"
const FinTechBasedDigitalHouse = () => {
    return (
        <Container id="fintech-based-digital-house" className="py-2 py-sm-2 py-md-5 py-lg-5">
            <Row>
                <Col md={6}>
                    <h1 className="fintech-based-digital-house-title">
                    Fin-Tech Based Digital Brokerage House
                    </h1>
                    <p className="fintech-based-digital-house-description py-5">
                        We arrange the Hackathons participants concoct and rejuvenate thoughts for applications, sites, robots, and the sky is the limit from there.Regardless of whether you 're simply beginning or you' ve been customizing for quite a long time, hackathons are an incredible spot to master new abilities, direct your organization, secure positions, and have some good times.
                    </p>
                    <ButtonComposition>{"Learn More"}</ButtonComposition>
                </Col>
                <Col md={6}>
                    <Image src={fintechBasedDigitalHouseImage} alt="fintech-based-digital-house-image" className="img-fluid"/>
                </Col>
            </Row>
        </Container>
    );
};

export default FinTechBasedDigitalHouse;