import React from 'react';
import "../styles/BestWayToInvest.scss";
import { Col, Container, Image, Row } from 'react-bootstrap';
import bestWayToInvestImage from "../assets/images/best-way-to-invest-image.png"
import ButtonComposition from './ButtonComposition/ButtonComposition';

const BestWayToInvest = () => {
    return (
        <Container id="best-way-to-invest" className="py-2 py-sm-2 py-md-5 py-lg-5">
            <Row className='align-items-center'>
                <Col md={6}>
                    <div>
                        <h1 className="best-way-to-invest-title">Better Way to <span>Invest</span></h1>
                        <p className="best-way-to-invest-description py-5">
                            Making world better being a tec professionals can be a good cause. CTO forum focuses on doing virtuous things implementing technological leadership and ideas. 
                        </p>
                        <ButtonComposition>{"Become a Member"}</ButtonComposition>
                    </div>
                </Col>
                <Col md={6}>
                    <Image src={bestWayToInvestImage} alt="best-way-to-invest-image" className="img-fluid"/>
                </Col>
            </Row>
        </Container>
    );
};

export default BestWayToInvest;