import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "../../styles/Footer.scss";
import brandLogo from "../../assets/images/brand_logo.png";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
const Footer = () => {
    return (
        <div id="footer">
            <Container>
            <Image src={brandLogo} alt="brand-logo" className='img-fluid pt-3' />
            <Row>
                <Col md={4}>
                    <section className="privacy d-flex align-items-center">
                        {/* <h6>Privacy</h6>
                        <h6>Terms of Service</h6> */}
                        <ul className="d-flex justify-content-between vw-100 m-0 p-0">
                            <li>Privacy</li>
                            <li>Terms of Service</li>
                        </ul>
                    </section>
                </Col>
                <Col md={2}>
                    <ul>
                        <span><li>The Forum</li></span>
                        <li>About</li>
                        <li>Executive Panel</li>
                        <li>Inquiry</li>
                    </ul>
                </Col>
                <Col md={2}>
                    <ul>
                        <span><li>Activity</li></span>
                        <li>Events</li>
                        <li>Activity Timeline</li>
                        <li>CTO Magazine</li>
                    </ul>
                </Col>
                <Col md={2}>
                    <ul>
                        <span><li>Membership</li></span>
                        <li>Member Directory</li>
                        <li>Membership Features</li>
                        <li>Registration</li>
                    </ul>
                </Col>
                <Col md={2}>
                    <ul>
                        <span><li>Resources</li></span>
                        <li>FAQ</li>
                        <li>News</li>
                        <li>Resources Corner</li>
                    </ul>
                </Col>
            </Row>
            <hr />
            <Row className="py-3">
                <Col md={4}>
                    <div className="design-and-develop">
                        Designed and developed by
                    </div>
                    <div className="footer-techsist-limited">
                        Techsist Ltd @2022
                    </div>
                </Col>
                <Col md={4}>
                    <span className="social-icon">
                    <FaFacebookF />
                    </span>
                    <span className="social-icon">
                    <FaTwitter />
                    </span>
                    <span className="social-icon">
                    <FaLinkedinIn/>
                    </span>
                </Col>
            </Row>
            </Container>
        </div>
    );
};

export default Footer;