import React from 'react';
import "../../styles/Header.scss";
import {Container,Nav,Navbar,Button, Image} from 'react-bootstrap';
import brandLogo from "../../assets/images/brand_logo.png"
const Header = () => {
    return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" id="navbar">
      <Container>
        <Navbar.Brand href="#home">
            <Image src={brandLogo} alt="brand-logo" className="brand-logo"></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#invest">Invest</Nav.Link>
            <Nav.Link href="#why-us">Why Us</Nav.Link>
            <Nav.Link href="#download">Download</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
          </Nav>
          <Nav>
            <Button href="#become-a-member" className="become-a-member mx-0 mx-sm-0 mx-md-2 mx-lg-2">Become a member</Button>
            <Button href="#login" className="navbar-login-button my-2 my-sm-2 my-md-0 my-lg-0">Login</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
};

export default Header;