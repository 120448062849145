import React from 'react';
import "../styles/InvesmentOffering.scss";
import { Container,Row,Col, Image } from 'react-bootstrap';
import ButtonComposition from './ButtonComposition/ButtonComposition';
import invesmentOfferingImage from "../assets/images/invesmentOfferingImage.png"

const InvesmentOffering = () => {
    return (
        <Container id="invesment-offering" className="py-2 py-sm-2 py-md-5 py-lg-5">
            <Row>
                <Col md={6} className="order-2 order-sm-2 order-md-0 0rder-lg-0">
                    <Image src={invesmentOfferingImage} className="img-fluid" alt="invesment-offering-image"/>
                </Col>
                <Col md={6} className="order-1 order-sm-1 order-md-0 0rder-lg-0">
                    <h1 className="invesment-offering-title">
                        Investment Offerings
                    </h1>
                    <p className="invesment-offering-description py-5">
                        Bangladesh is an independent, non - profit, non - political organization dedicated to fostering a trusted, open and creative environment where the brightest minds of our time convene to address the banking industry 's important issues. The CTO Forum members collaborate and develop solutions that harness the extraordinary potential of technology.
                    </p>
                    <ButtonComposition>{"Learn More"}</ButtonComposition>
                </Col>
            </Row>
        </Container>
    );
};

export default InvesmentOffering;