import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import "../styles/WhatPeopleSay.scss";
import whatPeopleSayImage1 from "../assets/images/whatPeopleSayImage1.png";
import whatPeopleSayImage2 from "../assets/images/whatPeopleSayImage2.png";
import whatPeopleSayImage3 from "../assets/images/whatPeopleSayImage3.png";

/*Swiper js import files*/

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../styles/SwiperSlider.scss";
// import required modules
import { Pagination } from "swiper";

const WhatPeopleSay = () => {
    return (
        <Container id="what-people-say" className="py-2 py-sm-2 py-md-5 py-lg-5">
            <h1 className="what-people-say title text-center">
                What People Say about Relief Exchange
            </h1>
            <p className="description py-5 text-center what-people-say-description">
                Magna nunc gravida senectus vulputate ligula fames ultricies quam vitae. Lectus tortor, facilisi pretium ac mauris augue turpis. Sed erat sapien vitae.
            </p>
            <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                <SwiperSlide>
                    <Row xs={1} md={3}>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage1} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Saidur Rahman Khan</h6>
                                    <h6 className='description'>Frame</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage2} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Tanvir Hasan Sadi</h6>
                                    <h6 className='description'>Mezro</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage3} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Rafeed Khan</h6>
                                    <h6 className='description'>Techsist</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </SwiperSlide>
                <SwiperSlide>
                    <Row xs={1} md={3}>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage1} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Saidur Rahman Khan</h6>
                                    <h6 className='description'>Frame</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage2} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Tanvir Hasan Sadi</h6>
                                    <h6 className='description'>Mezro</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage3} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Rafeed Khan</h6>
                                    <h6 className='description'>Techsist</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </SwiperSlide>
                <SwiperSlide>
                    <Row xs={1} md={3}>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage1} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Saidur Rahman Khan</h6>
                                    <h6 className='description'>Frame</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage2} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Tanvir Hasan Sadi</h6>
                                    <h6 className='description'>Mezro</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <div className="name-and-designation d-flex align-items-center">
                                    <img src={whatPeopleSayImage3} className="img-fluid" alt="" />
                                    <div className='text-start ms-4'>
                                    <h6 className='name'>Rafeed Khan</h6>
                                    <h6 className='description'>Techsist</h6>
                                    </div>
                                </div>
                                <div className="description text-start py-3 what-people-say-member-description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                                    <h6 className="associate-member"> - Associate Member</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </SwiperSlide>
            </Swiper>
        </Container>
        
    );
};

export default WhatPeopleSay;